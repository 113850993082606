@import 'Styles/includes';

.HearingCareGuidance {
    $root: &;

    margin-top: 48px;
    margin-bottom: 48px;

    @include media(M) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &--Overlap {
        margin-top: -48px;

        @include media(M) {
            margin-top: -32px;
        }
    }

    &__Wrap {
        @include wrap;
    }

    &__Title {
        @include textstyle(h2);

        margin-bottom: 24px;

        @include media(M) {
            margin-bottom: 40px;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        margin-bottom: -16px;

        @include media(M) {
            margin-left: -24px;
            margin-bottom: -24px;
        }

        @include media(L) {
            margin-left: -38px;
            margin-bottom: -38px;
        }
    }

    &__Item {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        width: calc(100% - 16px);
        margin-left: 16px;
        margin-bottom: 16px;

        @include media(M) {
            width: calc(50% - 24px);
            margin-left: 24px;
            margin-bottom: 24px;
        }

        @include media(L) {
            width: calc(50% - 38px);
            margin-left: 38px;
            margin-bottom: 38px;
        }
    }

    &__Card {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 24px 16px;
        border-radius: 4px;
        background-color: $red-5;

        @include media(M) {
            padding: 24px;
        }

        @include media(L) {
            padding: 32px;
        }

        &--Icon {
            margin-top: 16px;

            @include media(M) {
                margin-top: 0;
            }
        }

        &--Full {
            @include media(M) {
                flex-direction: row;
            }
        }

        &--HasLink {
            padding-right: 80px;

            @include media(M) {
                padding-right: 88px;
            }

            @include media(L) {
                padding-right: 104px;
            }

            &::after {
                display: block !important;
            }
        }

        &::after {
            content: '';
            display: none;
            align-self: flex-end;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            width: 40px;
            height: 40px;
            background-color: white;
            border-radius: 100vmax;
            background-image: url(#{$assetsPath}/img/arrow-large--red.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 18px;
            transition: transform 300ms;
            pointer-events: none;

            @include media(M) {
                right: 24px;
            }

            @include media(L) {
                right: 32px;
            }
        }

        &:hover::after {
            transform: translateX(8px);
        }
    }

    &__CardLink {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__CardContent {
        flex: 1 1 auto;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        #{$root}__Card--Full & {
            @include media(M) {
                width: 50%;
                max-width: 576px;
                margin-right: auto;
                padding-right: 24px;
            }

            @include media(L) {
                padding-right: 32px;
            }
        }

        #{$root}__Card--Icon & {
            padding-top: 20px;

            @include media(M) {
                padding-top: 0;
                padding-left: (48px + 16px);
            }

            @include media(L) {
                padding-left: (72px + 24px);
            }
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: -40px;
            left: 0;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $red-10;
            background-repeat: no-repeat;
            background-size: 28px 28px;
            background-position: center center;

            @include media(M) {
                top: 0;
            }

            @include media(L) {
                width: 72px;
                height: 72px;
                background-size: 40px 40px;
            }

            #{$root}__Card--Icon & {
                display: block;
            }

            #{$root}__Card--Phone & {
                background-image: url('#{$assetsPath}/img/phone--red-duotone.svg');
            }

            #{$root}__Card--Mail & {
                background-image: url('#{$assetsPath}/img/mail--red-duotone.svg');
            }

            #{$root}__Card--Pin & {
                background-image: url('#{$assetsPath}/img/pin--red-duotone.svg');
            }
        }
    }

    &__CardSelect {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 24px;

        #{$root}__Card--Full & {
            @include media(M) {
                width: 50%;
                margin-top: 0;
                padding-left: 12px;
            }

            @include media(L) {
                padding-left: 19px;
            }
        }
    }

    &__CardTitle {
        @include textstyle(h4);

        margin-bottom: 12px;

        @include media(M) {
            margin-bottom: 16px;
        }
    }

    &__CardText {
        @include textstyle(body-regular);

        p {
            margin: 8px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            display: inline-flex;
            color: $red;
            text-decoration: underline;
        }
    }
}
